import { BrowserRouter, Routes, Route } from 'react-router-dom'
import EditCustomer from './components/EditCustomer'
import FinalList from './components/FinalList'
import EditFinal from './components/EditFinal'
import DisplayFinal from './components/DisplayFinal'
import AddManagerReview from './components/AddManagerReview'
import CheckTenantFinal from './components/CheckTenantFinal'
import Reports from './components/Reports'
import RepairList from './components/RepairList'
import { AddRepair } from './components/AddRepair'
import RoomGrid from './components/RoomGrid'
import AmenityList from './components/AmenityList'
import LandingPage from './components/LandingPage'
import EditRepair from './components/EditRepair'
import { AddAmenity } from './components/AddAmenity'
import EditAmenity from './components/EditAmenity'
import IndividualTenant from './components/IndividualTenant'
import SendSMS from './components/SendSMS'
import Sample from './components/Sample'
import GuestManagement from './components/GuestManagement'
import { CheckinForm } from './components/CheckinForm.js'
import AddPayment from './components/AddPayment'
import { Repairs } from './components/Repairs.js'
import { Amenities } from './components/Amenities.js'
import GuestReviews from './components/GuestReviews.js'
import CustomerWizard from './components/CustomerWizard.js'
import SampleWizard from './components/SampleWizard.js'
import { Rooms } from './components/rooms.js'
import AllTenants from './components/AllTenants'
import NewPost from './components/AddImage'
import DisplayImages from './components/DisplayImages'
import Header from './components/Header'
import PreviousGuests from './components/PreviousGuests'
import { TenantHistory } from './components/TenantHistory'
import CreatePastTenancyCards from './components/CreatePastTenancyCards'
import RoomAvailabilityReport from './components/RoomAvailabilityReport'
import MonthlyCalender from './components/MonthlyCalender'
import AddReview from './components/AddReview'
import CashManagement from './components/CashManagement/CashManagement'
import { ThreeDayNoticeForm } from './components/ThreeDayNoticeForm'
import { Inqueries } from './components/Inqueries'
import RoomReadyChecklist from './components/RoomReadyChecklist'
import AddVacancyActivity from './components/AddVacancyActivity.js'
import VacancyTrackingReport from './components/VacancyTrackingReport.js'
import VacancyActivityReport from './components/VacancyActivityReport.js'
import TenantPaymentSummary from './components/TenantPaymentSummary.js'
import DepositWizard from './components/DepositWizard'
import PaymentsToDeposit from './components/CashManagement/PaymentsToDeposit.js'
import EditDeposit from './components/CashManagement/EditDeposit.js'
import DailyReport from './components/DailyReport.js'
import DepositReport from './components/DepositReport.js'
import FreeDaysDeposit from './components/FreeDaysReport.js'
import CurrentCash from './components/CurrentCash/CurrentCash'
import InventoryReport from './components/Inventory/InventoryReport';
import SearchCustomers from "./components/SearchCustomers.js"
import TenancyTimeline from './components/TenancyTimeline.js'
import ActiveTenantsReport from './components/ActiveTenantsReport.js';
import LoginPage from './components/LoginPage.js'
import ProtectedRoute from './components/ProtectedRoute';
import CustomerEnquiry from './components/CustomerPages/CustomerEnquiry.jsx';
import LineChartComponent from './components/LineChart.jsx'
import PaymentHistory from './components/PaymentHistory.jsx'
import Dashboard from './components/AdminDashboard/Dashboard.jsx'
import FeedbackReport from './components/CustomerPages/FeedBackReport.jsx'
import MainPage from './components/CustomerMainPages/MainPage.jsx'
import './AxiosSetup.js'; // This will apply the interceptor globally
import TaxMonthlyReport from './components/TaxCalculations/TaxMonthlyReport.js'
function App() {
  // const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
          path="/"
          element={<MainPage />}
        />
      <Route element={<ProtectedRoute requiredRole={["admin"]} />}>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
          path="/customerInquiryReport"
          element={<FeedbackReport/>}
        />
      </Route>
      <Route element={<ProtectedRoute requiredRole={["admin","manager"]} />}>
        <Route path="/calender" element={<MonthlyCalender />} />
        <Route path="/taxCalculations" element={<TaxMonthlyReport />} />
        <Route path="/lineChart" element={<LineChartComponent />} />
        <Route path="/activeTenants" element={<ActiveTenantsReport />} />
        <Route path="/search?" element={<GuestManagement />} />
        <Route path="/searchCustomers" element={<SearchCustomers />} />
        <Route path="/tenancyTimeline" element={<TenancyTimeline />} />
        <Route path="/addPastTenancy" element={<CreatePastTenancyCards />} />
        <Route path="/roomAvailability" element={<RoomAvailabilityReport />} />
        <Route path="/images" element={<NewPost />} />
        <Route path="/tenantHistory" element={<TenantHistory />} />
        <Route path="/customerWizard" element={<CustomerWizard />} />
        <Route path="/SampleWizard" element={<SampleWizard />} />
        <Route path="allImages" element={<DisplayImages />} />
        <Route path="/tenants/:id/guestReviews" element={<AddReview />} />
        <Route path="/tenants/:id/allReviews" element={<GuestReviews />} />
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/paymentsToDeposit" element={<PaymentsToDeposit />} />
        <Route path="/deposits/:depositId" element={<EditDeposit />} />
        <Route path="/tenants/add" element={<RoomAvailabilityReport />} />
        <Route path="/tenants/add/:id" element={<GuestManagement />} />
        <Route path="/tenants" element={<AllTenants />} />
        <Route path="tenants/:id" element={<IndividualTenant />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/tenants/:id/edit" element={<EditFinal />} />
        <Route path="/tenants/:id/checkinForm" element={<CheckinForm />} />
        <Route path="/tenants/:id/display" element={<DisplayFinal />} />
        <Route path="/payments" element={<AddPayment />} />
        <Route path="/paymentsHistory" element={<PaymentHistory />} />
        <Route path="/prevTenants" element={<PreviousGuests />} />
        <Route path="/tenants/:id/checkout" element={<CheckTenantFinal />} />

        <Route
          path="/tenants/:id/managerReviews"
          element={<AddManagerReview />}
        />
        <Route path="/repairs" element={<Repairs />} />
        <Route path="/repairs/:roomId" element={<Repairs />} />
        <Route path="/repairs/add" element={<AddRepair />} />
        <Route path="/repairs/edit/:id" element={<EditRepair />} />
        <Route path="/amenities" element={<Amenities />} />
        <Route path="/amenities/:roomId" element={<Amenities />} />
        <Route path="/amenities/add" element={<AddAmenity />} />
        <Route path="/amenities/edit/:id" element={<EditAmenity />} />
        <Route path="/tenants/:id/customerData" />
        <Route path="/sms" element={<SendSMS />} />
        <Route path="/cashManagement" element={<CashManagement />} />
        <Route path="/currentCash" element={<CurrentCash />} />
        <Route path="/inventory" element={<InventoryReport />} />
        <Route path="/threeDayNoticeForm" element={<ThreeDayNoticeForm />} />
        <Route path="/inqueries" element={<Inqueries />} />
        <Route path="/roomCheckList" element={<RoomReadyChecklist />} />
        <Route path="/deposits" element={<DepositReport />} />
        <Route path="/inqueries" element={<Inqueries />} />
        <Route path="/dailyReport" element={<DailyReport />} />
        <Route path="/freeDaysReport" element={<FreeDaysDeposit />} />
        <Route
          path="/vacancyActivity/:roomId"
          element={<AddVacancyActivity />}
        />
        <Route
          path="/VacancyTrackingReport"
          element={<VacancyTrackingReport />}
        />
        <Route
          path="/VacancyActivityReport/:vacancyTrackingId"
          element={<VacancyActivityReport />}
        />
        <Route
          path="/tenantPaymentSummary/:tenancyId"
          element={<TenantPaymentSummary />}
        />
        {/* <Route path="/basics" element={<SampleComponent/>}/> */}

        <Route
          path="tenants/:id1/edit/customers/:id/edit"
          element={<EditCustomer />}
        />
        {/* </>
      } */}
      </Route>
      <Route
          path="/customerInquiry"
          element={<CustomerEnquiry />}
        />
        
      </Routes>
    </BrowserRouter>
  )
}

export default App
